import { useContext, useEffect, useRef, useState } from "react";
import { Col, Spinner, Alert } from "react-bootstrap";
import GithubIcon from "../../../assets/iconfonts/bootstrap-icons/icons/icons/github.svg";
import { getJobId, getProjectById, getProjectsForUser } from "../../../network/ApiAxios";
import axios from "axios";
import { DeployProgressProps } from "../../../models/NewProjectFlowModels";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../shade/layouts/App";
import { getEmbedProject } from "../../../utils";

const DeployProgress: React.FC<DeployProgressProps> = ({ githubRepositoryId, projectName, jobID }) => {
  const enabledOnDevFlag = process.env.REACT_APP_GITHUB_APP_NAME === "genezio-dev";

  const navigate = useNavigate();
  const [jobId, setJobId] = useState<string>();
  const [deployProgress, setDeployProgress] = useState<any>();

  const { setProjectCodeArchive, setProjectKey } = useContext<any>(AppContext);

  const API_BASE_URL = process.env.REACT_APP_BUILD_API_BASE_URL;

  const isGettingProjectCode = useRef(false);

  const fetchJobId = async () => {
    if (jobID) {
      setJobId(jobID);
      return;
    }

    // Get the job id getJobIdInfo() every 3 seconds until we get a job id
    const intervalId = setInterval(async () => {
      const jobIdLocal = await getJobIdInfo();
      if (jobIdLocal) {
        clearInterval(intervalId);
      }
    }, 3000);
  };

  async function getJobIdInfo() {
    const res: any = await getJobId(githubRepositoryId);
    if (res.status === 200 && res.data.status === "ok") {
      setJobId(res.data.jobId);
      return res.data.jobId;
    }
    return undefined;
  }

  const fetchDeployProgress = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/state/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
          "Content-Type": "application/json",
        },
        params: {
          tokenName: "Auto Generated Token By Browser Getting Started - " + new Date().toISOString(),
        },
      });

      if (response.data.BuildStatus === "SUCCEEDED") {
        // get the project id
        const resProjects = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
        const project = resProjects.data?.projects.find((project: { name: any }) => project.name === projectName);

        const res: any = await getProjectById(project.id ?? "");
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.name === "prod");

        navigate(`/edit-code/${project.id}/${localActiveEnv.id}?showModalURLs=true`);
      }

      setDeployProgress(response.data);
      if (response.data.BuildStatus === "FAILED") {
        return false;
      }

      if (
        response.data.Transitions.find((transition: any) => transition.From === "CREATING_EMPTY_PROJECT") &&
        !isGettingProjectCode.current
      ) {
        const resProjects = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
        const project = resProjects.data?.projects.find((project: { name: any }) => project.name === projectName);

        const res: any = await getProjectById(project.id ?? "");
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.name === "prod");

        setProjectKey(`${project.id}_${localActiveEnv.id}`);
        setProjectCodeArchive(getEmbedProject(res.data.project.name, res.data.project.region, localActiveEnv.name));
        isGettingProjectCode.current = true;
      }
    } catch (error) {
      console.error("Error fetching deployment progress:", error);
    }
  };

  useEffect(() => {
    if (!jobId) {
      const intervalId = setInterval(() => {
        fetchJobId();
      }, 1000);
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [jobId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const res = await fetchDeployProgress();
      if (res === false) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [jobId]);

  const capitalize = (s: string) => {
    const words = s.split("_");
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  };

  return (
    <>
      <Col lg={1}></Col>
      <Col className="border rounded p-4" lg={8}>
        <div className="p-0 w-100">
          {/* Card header */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h4 className="mb-2 fw-bold">Deploying your project {projectName}</h4>
              <p className="m-0 text-muted">Please wait while we deploy your project. It might take a few minutes.</p>
            </div>

            <div>
              <img src={GithubIcon} alt="github_icon" style={{ width: "63px" }} className="invert-dark-theme" />
            </div>
          </div>

          {/* Deploy progress */}
          <div className="m-auto d-flex flex-column align-items-center border rounded p-3">
            {/* Ai Insights - Remove enabledOnDevFlag to enable the AI insights on production */}
            {enabledOnDevFlag && deployProgress?.Insights && (
              <div className="d-flex w-100 align-items-start justify-content-start mb-3">
                <svg
                  fill="#ffdf4f"
                  height="20px"
                  width="60px"
                  version="1.1"
                  id="Icons"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  stroke="#ffdf4f"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path d="M12,17c0.8-4.2,1.9-5.3,6.1-6.1c0.5-0.1,0.8-0.5,0.8-1s-0.3-0.9-0.8-1C13.9,8.1,12.8,7,12,2.8C11.9,2.3,11.5,2,11,2 c-0.5,0-0.9,0.3-1,0.8C9.2,7,8.1,8.1,3.9,8.9C3.5,9,3.1,9.4,3.1,9.9s0.3,0.9,0.8,1c4.2,0.8,5.3,1.9,6.1,6.1c0.1,0.5,0.5,0.8,1,0.8 S11.9,17.4,12,17z"></path>{" "}
                      <path d="M22,24c-2.8-0.6-3.4-1.2-4-4c-0.1-0.5-0.5-0.8-1-0.8s-0.9,0.3-1,0.8c-0.6,2.8-1.2,3.4-4,4c-0.5,0.1-0.8,0.5-0.8,1 s0.3,0.9,0.8,1c2.8,0.6,3.4,1.2,4,4c0.1,0.5,0.5,0.8,1,0.8s0.9-0.3,1-0.8c0.6-2.8,1.2-3.4,4-4c0.5-0.1,0.8-0.5,0.8-1 S22.4,24.1,22,24z"></path>{" "}
                      <path d="M29.2,14c-2.2-0.4-2.7-0.9-3.1-3.1c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0-0.9,0.3-1,0.8c-0.4,2.2-0.9,2.7-3.1,3.1 c-0.5,0.1-0.8,0.5-0.8,1s0.3,0.9,0.8,1c2.2,0.4,2.7,0.9,3.1,3.1c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8 c0.4-2.2,0.9-2.7,3.1-3.1c0.5-0.1,0.8-0.5,0.8-1S29.7,14.1,29.2,14z"></path>{" "}
                      <path d="M5.7,22.3C5.4,22,5,21.9,4.6,22.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C4,22.7,4,22.9,4,23s0,0.3,0.1,0.4 c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2C4.7,24,4.9,24,5,24c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.2 c0.1-0.1,0.2-0.2,0.2-0.3C6,23.3,6,23.1,6,23s0-0.3-0.1-0.4C5.9,22.5,5.8,22.4,5.7,22.3z"></path>{" "}
                      <path d="M28,7c0.3,0,0.5-0.1,0.7-0.3C28.9,6.5,29,6.3,29,6s-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0 c-0.1,0-0.2,0.1-0.3,0.2C27.1,5.5,27,5.7,27,6c0,0.3,0.1,0.5,0.3,0.7C27.5,6.9,27.7,7,28,7z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
                <p className="ms-1 text-muted mb-0">Genezio AI: {deployProgress?.Insights}</p>
              </div>
            )}

            {/* Loading alert until we have output from the build machine */}
            {(!deployProgress || deployProgress?.Transitions.length < 1) && (
              <Alert variant="warning w-100">
                <p className="text-muted m-0">Preparing the deployment...</p>
              </Alert>
            )}

            {/* Handle errors */}
            {deployProgress?.Transitions?.find((transition: any) => transition?.To === "FAILED")?.To === "FAILED" && (
              <Alert variant="danger w-100">
                <p className="m-0">{deployProgress?.Transitions?.find((item: any) => item.Stderr !== "")?.Stderr}</p>
              </Alert>
            )}

            {deployProgress?.Transitions?.map((progress: any, index: number) => (
              <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                <h6 className="m-0 fw-semibold">{capitalize(progress?.To)}</h6>

                {index === deployProgress.Transitions.length - 1 && progress?.To !== "FAILED" ? (
                  <Spinner variant="primary" animation="border" size="sm" />
                ) : (
                  <>
                    {progress?.To === "FAILED" ? (
                      <img src={require("../../../assets/img/svgs/wrong_mark.svg").default} alt="integrationStatus" />
                    ) : (
                      <img src={require("../../../assets/img/svgs/check_mark.svg").default} alt="integrationStatus" />
                    )}
                  </>
                )}
              </div>
            ))}

            {/* {deployProgress?.BuildStatus !== "SUCCEEDED" &&
            deployProgress?.BuildStatus !== "FAILED" &&
            deployProgress?.Transitions.length > 0 ? (
              <Spinner variant="primary" animation="border" />
            ) : (
              ""
            )} */}
          </div>
        </div>
      </Col>
    </>
  );
};

export default DeployProgress;
